import { render, staticRenderFns } from "./ItemMenu.vue?vue&type=template&id=062be2ca&scoped=true&lang=pug&"
import script from "./ItemMenu.vue?vue&type=script&lang=js&"
export * from "./ItemMenu.vue?vue&type=script&lang=js&"
import style0 from "./ItemMenu.vue?vue&type=style&index=0&id=062be2ca&lang=scss&scoped=true&"
import style1 from "./ItemMenu.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "062be2ca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VIcon,VTextarea})
