<template lang="pug">
  div(v-if="trainer").wrap-item-trainer
    div.f.fm
      v-icon.mr8 group
      // div.wrap-icon.f.fh.mr8
        img(:src="trainer.photoURL")
      span.name {{trainer.name}}

</template>

<style lang="scss" scoped>
.wrap-item-trainer {
  .wrap-icon {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name {
    font-size: 14px;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  props: {
    trainerId: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      trainer: null
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created () {
    this.trainer = await this.getUserByUid(this.trainerId)
  },
  methods: {
    ...mapActionAuth([
      'getUserByUid'
    ])
  }
}
</script>
