<template lang="pug">
  div.wrap-item-sub-header.f.fh
    div.wrap-icons.f.fc
      div.wrap-selector.f.fm
        div(@click="() => { showPullDow = !showPullDow }").f.fm.cursor-pointer
          v-icon(v-if="type === 'karte'" color="indigo" size="24px") description
          v-icon(v-if="type === 'consultation'" color="green" size="24px") hearing
          v-icon(v-if="type === 'inbody'" color="orange" size="24px") accessibility
          span.line-clamp-1.pt2.px4.fz14 {{headerLabel}}
          div(:class="{'is-active': showPullDow}").wrap-down-icon.f.fh
            v-icon(color="#fff" ) arrow_drop_down
        div(v-if="showPullDow").wrap-selection.py2.px2
          div.wrap-tab.f.fm.flex-betweem.px2
            div(@click="onTab('karte')"
              :class="{'active-karte': currentTab === 'karte'}").tab.f.fc.py8
              v-icon(:color="(currentTab === 'karte') ? 'indigo' : '#fff'"
                size="24px") description
            div(@click="onTab('consultation')"
              :class="{'active-consultation': currentTab === 'consultation'}").tab.f.fc.py8
              v-icon(:color="(currentTab === 'consultation') ? 'green' : '#fff'"
                size="24px") hearing
            div(@click="onTab('inbody')"
              :class="{'active-inbody': currentTab === 'inbody'}").tab.f.fc.py8
              v-icon(:color="(currentTab === 'inbody') ? 'orange' : '#fff'"
                size="24px") accessibility
          div.wrap-records
            div(v-for="item in filteredRecordsList" @click="onSelection(item)").selection.f.fm.px10.py6.cursor-pointer
              div.icon
                v-icon(v-if="item.type === 'karte'" color="indigo" size="24px") description
                v-icon(v-if="item.type === 'consultation'" color="green" size="24px") hearing
                v-icon(v-if="item.type === 'inbody'" color="orange" size="24px") accessibility
              span.line-clamp-1.pt2.px4.fz14 {{item.label}}

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-item-sub-header {
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  height: 48px;
  background: $active_color;
  border-top: $basic_border;
  z-index: 101;
  .wrap-icons {
    // position: relative;
    width: 93%;
    max-width: 820px;
    margin: 0 auto;
    span {
      font-size: 18px;
      max-width: 300px;
    }
    // .to-last {
    //   position: absolute;
    //   right: -34px;
    // }

    .wrap-selector {
      position: relative;
      .wrap-down-icon {
        transition: all 200ms linear;
        &.is-active {
          transform: rotate(180deg);
        }
      }

      .wrap-selection {
        position: absolute;
        left: calc(50% - 150px);
        top: 35px;
        width: 300px;
        background: $active_color;
        .wrap-tab {
          .tab {
            width: calc(100% / 3);
            border-bottom: solid #fff 0.6px;
            cursor: pointer;
            &.active-karte {
              border-bottom: solid #3f51b5 0.6px;
            }
            &.active-consultation {
              border-bottom: solid #4caf50 0.6px;
            }
            &.active-inbody {
              border-bottom: solid #ff9800 0.6px;
            }
          }
        }
        .wrap-records {
          overflow: scroll;
          max-height: 280px;
          .icon {
            width: 28px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
// .wrap-item-sub-header {
//   .wrap-drop-down {
//     width: 100%;
//     .wrap-selection {
//       background: #2a2a2a;
//       span {
//         color: #fff;
//       }
//     }
//   }
// }

// .wrap-sub-header-selection {
//   background: #2a2a2a;
//   span {
//     color: #fff;
//   }
// }
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import format from 'date-fns/format'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const { mapState: mapStateRecord, mapActions: mapActionsRecord } = createNamespacedHelpers('record')

export default {
  components: {
  },
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    record: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      recordsList: [],
      filteredRecordsList: [],
      isPreviewMode: false,
      showPullDow: false,
      currentTab: 'karte'
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...mapStateRecord(['records']),
    headerLabel () {
      return `${format(new Date(this.record.created.toDate()), 'yyyy/M/d')} | ${this.record.label}`
    }
  },
  async created () {
    this.isPreviewMode = (this.$route.name === 'preview')
    this.recordsList = this.records.map(e => {
      return {
        label: `${format(new Date(e.created.toDate()), 'yyyy/M/d')} | ${e.label}`,
        type: e.type,
        clientId: e.clientId,
        id: e.id
      }
    })
    
    this.currentTab = this.type
    this.filteredRecordsList = this.recordsList.filter(e => e.type === this.currentTab)
  },
  methods: {
    ...mapActionsRecord([
      'getPreviousRecordOf',
      'getNextRecordOf',
      'getLatestRecordOf'
    ]),
    onSelection (record) {
      // var params = this.$route.params
      this.$router.push(`/record/${record.type}/${record.clientId}/${record.id}`)
      this.$nextTick(() => {
        this.$emit('init')
      })
      this.showPullDow = false

      // mixpanel.track('onSelection (sub header)', { createdAt: new Date() })
    },
    onTab (type) {
      this.currentTab = type
      this.filteredRecordsList = this.recordsList.filter(e => e.type === this.currentTab)
    }
  }
}
</script>
