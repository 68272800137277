<template lang="pug">
  //- div(v-if="set").wrap-atom-editable-set.f.fm.mr16.mb16
    div.wrap-set-input.mr4
      v-autocomplete(v-if="set.unit !== '自重'"
        v-model="set.weight"
        :items="unitNum"
        :suffix="set.unit"
        color="white")
      v-text-field(v-else value="自重" disabled)
    div.wrap-set-input
      v-autocomplete(v-model="set.count"
        :items="unitNum"
        :suffix="set.countUnit"
        color="white")
  
  div(v-if="set").wrap-atom-editable-set.f.fm.mr10.mb16
    div.wrap-input.f.fm.mr10
      select(v-if="set.unit !== '自重'"
        v-model.number="set.weight"
        type="number").value-weight-select.mr2
        option(v-for="item in unitNum") {{item}}
      span(v-if="set.unit !== '自重'").mr12 {{set.weight}}
      span(v-if="set.unit !== '自重'").weight-suffix {{set.unit}}
      span(v-if="set.unit === '自重'") 自重
    div.wrap-input.f.fm.mr6
      // input.input-count(v-model="valueCount").mr4
      select(v-model="set.count").mr2
        option(v-for="item in unitCountNum") {{item}}
      span.mr4 {{set.count}}
      span.count-suffix {{set.countUnit}}
    div.wrap-icon.mr6
      v-icon(@click="deleteSetRecord" size="20px" color="#999") close

</template>

<style lang="scss" scoped>
.wrap-atom-editable-set {
  height: 24px;
  border-right: solid rgba(0, 0, 0, 0.4) 1px;
  // .wrap-set-input {
  //   max-width: 100px;
  // }
  .wrap-input {
    position: relative;
    border-bottom: solid 1px;
    input {
      text-align: center;
      outline: none;
    }
    span {
      min-width: 20px;
    }
    select {
      text-align: center;
      outline: none;
      position: absolute;
      left: 0;
      right: 0;
      opacity: 0.0;
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const { mapActions: mapActionsRecord } = createNamespacedHelpers('record')

// prettier-ignore
export default {
  components: {
  },
  props: {
    setId: {
      type: String,
      required: true
    },
    recordBodyContent: {
      type: Object,
      required: true
    },
    selectedTraining: {
      type: Object,
      required: true
    },
    defaultUnit: {
      type: String,
      required: true
    },
    defaultCountUnit: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      set: null,
      valueWeight: 0,
      valueCount: 0,
      inInitialized: false
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    unitNum: () => {
      var list = []
      for (var i = 0; i < 400.25; i = Number((i + 0.25).toFixed(2))) {
        // list.push(String(i))
        list.push(i)
      }
      return list
    },
    unitCountNum: () => {
      var list = []
      for (var i = 0; i < 400.25; i++) {
        list.push(i)
      }
      return list
    }
  },
  watch: {
    async setId (newSetId, oldSetId) {
      if (newSetId !== oldSetId) this.set = await this.getSet(this.setId)
    },
    'set': {
      handler (newSet) {
        if (this.inInitialized) {
          this.updateSet({ id: this.setId, set: newSet })
        }
        this.inInitialized = true
      },
      deep: true
    }
  },
  async created () {
    this.set = await this.getSet(this.setId)

    var isLastSet = this.recordBodyContent.sets[this.recordBodyContent.sets.length - 1] === this.setId
    if (isLastSet && this.recordBodyContent.sets.length !== 0) {
      this.$emit('setDefaultUnit', this.defaultUnit)
      this.$emit('setDefaultCount', this.defaultCountUnit)
      this.$emit('initMenuUnitEditor', {
        defaultUnit: this.set.unit,
        defaultCountUnit: this.set.countUnit
      })
    }
  },
  methods: {
    ...mapActionsRecord(['getSet', 'updateSet', 'deleteSet']),
    async updateSetRecord () {
      // this.updateSet(this.set)
      this.set = await this.getSet(this.setId)
    },
    async deleteSetRecord () {
      var letDelete = confirm('本当に削除しますか？')
      if (!letDelete) return

      var self = this
      self.recordBodyContent.sets = this.recordBodyContent.sets.filter(e => {
        return (e !== this.setId)
      })
      // this.$emit("deleteSet", this.setId)
      this.deleteSet(this.setId)

      this.$emit('autoSave')

      // mixpanel.track('onDeleteSetRecord', { createdAt: new Date() })
    }
  }
}
</script>
