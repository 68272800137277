<template lang="pug">
  div(v-if="set").wrap-atom-set.f.fm.pr12.mr12.mb12
    span(v-if="set.unit !== '自重'").mr8 {{`${set.weight} ${set.unit}`}}
    span(v-if="set.unit === '自重'").mr8 {{set.unit}}
    span.mr4 {{`${set.count} ${set.countUnit}`}}
    div.wrap-icon
      v-icon(@click="deleteSetRecord" size="20px" color="#999") close
  
  // span {{`セット${set.title}：`}}
  // div(v-if="set").wrap-atom-set
     div.f.flex-between
      div.f.mr12
        span.set-label.mb12.mr8.mt6 {{`セット${set.title}：`}}
        v-text-field(
          v-model="set.weight"
          :suffix="set.unit"
          color="#2a2a2a"
          @change="$emit('autoSave')"
          number).small-input.mr12
        v-text-field(
          v-model="set.count"
          :suffix="set.countUnit"
          color="#2a2a2a"
          @change="$emit('autoSave')"
          number).small-input.mr8
        v-text-field(v-model="set.memo" placeholder="メモ" color="#2a2a2a").memo-input
        v-icon(@click="deleteSetRecord" size="20px" color="#999").mb10 close

</template>

<style lang="scss">
.wrap-atom-set {
  display: inline-block;
  border-right: solid rgba(0, 0, 0, 0.4) 1px;
  .wrap-icon {
    position: relative;
    top: 1px;
    display: inline-block;
  }
  // .set-label {
  //   word-break: keep-all;
  // }
  // .v-input {
  //   margin: 0;
  //   padding: 0;
  // }
  // .small-input {
  //   .v-text-field__details {
  //     display: none;
  //   }
  // }
  // .memo-input {
  //   width: 90%;
  // }
}
</style>

<style lang="scss">
.wrap-atom-set {
  .v-input {
    input {
      width: 32px;
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions: mapActionsRecord } = createNamespacedHelpers('record')

export default {
  props: {
    setId: {
      type: String,
      required: true
    },
    recordBodyContent: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      set: null
    }
  },
  watch: {
    async setId (newSetId, oldSetId) {
      if (newSetId !== oldSetId) this.set = await this.getSet(this.setId)
    }
  },
  async created () {
    this.set = await this.getSet(this.setId)
    if (this.recordBodyContent.sets.length !== 0
        && this.recordBodyContent.sets.length - 1 === this.index) {
      this.$emit("setDefaultWeight", this.set.weight)
      this.$emit("setDefaultCount", this.set.count)
    }
  },
  methods: {
    ...mapActionsRecord(['getSet', 'updateSet', 'deleteSet']),
    async updateSetRecord () {
      // this.updateSet(this.set)
      this.set = await this.getSet(this.setId)
    },
    async deleteSetRecord () {
      var letDelete = confirm("本当に削除しますか？")
      if (!letDelete) return

      this.recordBodyContent.sets = this.recordBodyContent.sets.filter(e => {
        return (e !== this.setId)
      })
      // this.$emit("deleteSet", this.setId)
      this.deleteSet(this.setId)

      this.$emit("autoSave")
    }
  }
}
</script>
