<template lang="pug">
  div(v-if="records").wrap-module-set-record.pb60
    ItemRecordSubHeader(
      v-if="record && records && $route.name !== 'client_record'"
      :record="record"
      :label="label"
      type="karte"
      @init="init")
    div(v-if="$route.name !== 'client_record'").pt70
    div.wrap-record-meta.f.fm.flex-between.mb40.pb14
      div.f.fm.flex-wrap
        ItemRecordClient(v-if="record" :clientId="record.clientId").mr12.s-mb8
        span.s-hide.mr8 /
        ItemRecordTrainer(v-if="record" :trainerId="record.uid").s-mb8
      ItemShareSetting(
        v-if="record"
        ref="shareSetting"
        :record="record"
        @autoSave="autoSave")
      // div.wrap-updated-at-in-set.f
        v-icon(color="#999" size="24px").mr4 check
        span(v-if="recordTimeStr").record-time.pt2 {{recordTimeStr}}

    div.wrap-record-info.mb4
      div.f.mt20
        v-text-field(
          v-if="previousRecord"
          v-model="label"
          label="タイトル"
          color="#2a2a2a"
          :hint="`前回のタイトル：${previousRecord.label}`"
          @change="autoSave").title-input.mr12
        v-text-field(
          v-else
          v-model="label"
          label="タイトル"
          color="#2a2a2a"
          @change="autoSave").title-input

      div.wrap-training-date.mb36.f.fm
        div
          span 実施日時
          ItemDateSetting(
            ref="dateSetting"
            v-if="trainingDate"
            :date="trainingDate"
            @autoSave="autoSave"
            @onChangeDate="onChangeDate")
          // div.f.fm
            div.dram-selector.f.fm
              select(v-model="clientWeight" @change="autoSave")
                option(v-for="item in clientWeightList") {{item}}
              span.dram-label 体重
              v-icon(size="14px" color="#2a2a2a") unfold_more
      div.f.fm.mb20
        div.dram-selector.f.fm.mr20
          select(v-model="clientWeight" @change="autoSave")
            option(v-for="item in clientWeightList") {{item}}
          span.dram-label 体重
          v-icon(size="14px") unfold_more
        div.dram-selector.f.fm.mr20
          select(v-model="maxPressure" @change="autoSave")
            option(v-for="item in minPressureList") {{item}}
          span.dram-label 最高血圧
          v-icon(size="14px") unfold_more
        div.dram-selector.f.fm
          select(v-model="minPressure" @change="autoSave")
            option(v-for="item in maxPressureList") {{item}}
          span.dram-label 最低血圧
          v-icon(size="14px") unfold_more

      v-textarea(
        v-if="previousRecord"
        v-model="memo"
        rows="4"
        label="メモ"
        :hint="`前回のメモ：${previousRecord.memo}`"
        color="#2a2a2a").mb12
      v-textarea(v-else v-model="memo" rows="4" label="メモ" color="#2a2a2a").mb12

    div.wrap-menu
      div.f.fm.flex-between.mb16
        h3.mr20 メニュー
      div(v-if="body[0]" v-for="(item, index) in body").menu.mb20
        ItemMenu(ref="itemMenu"
          v-if="!item.photoURL"
          :content="item"
          :trainingList="trainingList"
          :index="index"
          :recentRecords="recentRecords"
          :previousRecord="previousRecord"
          @autoSave="autoSave"
          @deleteTrainingMenu="deleteTrainingMenu"
          @contentUpdated="updateContent",
          @trainingListUpdated="updateTrainingList")
        div(v-else).wrap-img.mb20
          div.img-glid
            img(:src="item.photoURL")
            div.wrap-remove
              v-icon(@click="removeImg(item.photoURL)" color="#2a2a2a" size="28px") cancel
        div(v-if="item.label || item.photoURL").wrap-swapper.f.fm
          div.swapper
            v-icon(@click='upMenu(index)') arrow_drop_up
            v-icon(@click='downMenu(index)') arrow_drop_down

      div(v-if="!body[0]").f.fc
        span.mt28.mb28 メニューがまだ追加されていません。
      div.f.flex-left.my28
        div(@click="addMenu").add-button.f.fm.pl6.pr10.py6.mr10
          v-icon(size="18px").mr2 add
          span メニューを追加
        ItemRecordImgUploader(
          :imgId="imgId"
          ref="mediaUploader"
          @afterUpload="afterUpload")

</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-set-record {
  width: 93%;
  max-width: 820px;
  margin: 0 auto;
  padding-top: 28px;
  .wrap-record-meta {
    border-bottom: $basic_border;
  }
  .wrap-sub-header {
    position: fixed;
    top: 48px;
    left: -1px;
    width: 100%;
    height: 36px;
    background: $secondary_bg_color;
    border-top: $secondary_border;
    span {
      font-size: 16px;
    }
  }
  .wrap-record-info {
    .title-input {
      width: 60%;
    }
    .dram-selector {
      position: relative;
      border-bottom: solid 1px #999;
      select {
        width: 42px;
        height: 30px;
        outline: none;
      }
      .dram-label {
        position: absolute;
        left: -1px;
        top: -16px;
        color: #999;
        font-size: 11px;
      }
    }
    .wrap-training-date {
      transform: translateY(-4px);
      span {
        color: #999;
        font-size: 10px;
      }
    }
  }
  .wrap-menu {
    .menu {
      position: relative;
      .wrap-img {
        position: relative;
        display: inline-block;
        img {
          width: 100%;
          max-width: 580px;
          object-fit: cover;
        }
        .wrap-remove {
          position: absolute;
          top: 12px;
          right: 12px;
          opacity: 0.8;
        }
      }
      .wrap-swapper {
        position: absolute;
        height: 100%;
        left: -28px;
        top: 0;
        .swapper {
          display: block;
          width: 14px;
        }
      }
    }
    .add-button {
      background: $active_color;
      border-radius: 3px;
      color: #fff;
      font-weight: bold;
      font-size: 12px;
    }
    .wrap-menu-about {
      .menu-selector {
        width: 80%;
      }
      .v-btn {
        margin: 0;
      }
    }
  }
}
</style>

<style lang="scss">
.wrap-updated-at-in-set {
  i {
    height: 24px;
  }
}
</style>

<script>
import moment from 'moment'

import { createNamespacedHelpers } from 'vuex'
import ItemRecordTrainer from '@/components/item/ItemRecordTrainer'
import ItemRecordClient from '@/components/item/ItemRecordClient'
import ItemRecordSubHeader from '@/components/item/ItemRecordSubHeader'
import ItemShareSetting from '@/components/item/ItemShareSetting'
import ItemMenu from '@/components/item/ItemMenu'
import ItemDateSetting from '@/components/item/ItemDateSetting'
import ItemRecordImgUploader from '@/components/item/ItemRecordImgUploader'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const { mapState: mapStateRecord, mapActions: mapActionsRecord } = createNamespacedHelpers('record')

export default {
  components: {
    ItemRecordSubHeader,
    ItemRecordTrainer,
    ItemRecordClient,
    ItemShareSetting,
    ItemMenu,
    ItemDateSetting,
    ItemRecordImgUploader
  },
  props: {
    autoSaveTrigger: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      record: null,
      docId: null,
      label: '',
      recordTimeStr: null,
      clientWeight: 30,
      maxPressure: 0,
      minPressure: 0,
      memo: '',
      body: [],
      menuItems: [],
      trainingDate: null,
      imgId: '',
      trainingList: [],
      previousRecord: null,
      recentRecords: [],
      autoSaveTimer: null
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...mapStateRecord(['records']),
    clientWeightList: () => {
      var lists = []
      for (var i = 10; i < 130; i = Number((i + 0.1).toFixed(1))) {
        lists.push(String(i))
      }
      return lists
    },
    maxPressureList: () => {
      var lists = []
      for (var i = 0; i < 200; i = Number((i + 0.1).toFixed(1))) {
        lists.push(String(i))
      }
      return lists
    },
    minPressureList: () => {
      var lists = []
      for (var i = 0; i < 200; i = Number((i + 0.1).toFixed(1))) {
        lists.push(String(i))
      }
      return lists
    }
  },
  watch: {
    records: function () {
      this.body = []
      this.body = this.records.filter((e) => {
        return this.docId === e.id
      })[0].body
    },
    autoSaveTrigger: function () {
      this.autoSave()
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    ...mapActionsRecord(['loadMenuItems', 'createEmptySet', 'saveRecord',
      'getRecordById', 'getPreviousRecordOf', 'getNextRecordOf', 'getRecentRecords',
      'replaceOneRecord']),
    async init() {
      this.docId = this.$route.params.recordId
      this.menuItems = await this.loadMenuItems()
      // this.menuItems = await this.loadMenuItems(this.uid)

      // 追加分のメニューを上にソートする
      // n等分に配列を分割する関数
      const split = (array, n) => array.reduce((a, c, i) => (i % n === 0 ? [...a, [c]] : [...a.slice(0, -1), [...a[a.length - 1], c]]), [])
      var defaultTrainingNum = 123
      var defaultTrainings = split(this.menuItems.body, defaultTrainingNum)[0]

      if (split(this.menuItems.body, defaultTrainingNum + 1)[1]) {
        var allMenu = this.menuItems.body
        var customTrainings = allMenu.splice(defaultTrainingNum + 1)
        // var customTrainings = split(this.menuItems.body, defaultTrainingNum + 1)[1].reverse()
      } else {
        customTrainings = []
      }

      this.trainingList = customTrainings.concat(defaultTrainings) // this.menuItems.body

      this.trainingList.push({
        label: '+ 新規トレーニングメニューの追加'
      })
      if (this.$route.params.recordId !== 'new') {
        this.record = await this.getRecordById(this.$route.params.recordId)
        this.label = this.record.label
        if (this.record.clientWeight) this.clientWeight = this.record.clientWeight
        if (this.record.maxPressure) this.maxPressure = this.record.maxPressure
        if (this.record.minPressure) this.minPressure = this.record.minPressure
        this.memo = this.record.memo
        this.body = this.record.body

        this.recentRecords = await this.getRecentRecords({
          created: this.record.created,
          clientId: this.$route.params.clientId,
          type: this.$route.params.recordType
        })
        // this.previousRecord = await this.getPreviousRecordOf({
        //   created: this.record.created,
        //   clientId: this.$route.params.clientId,
        //   type: this.$route.params.recordType
        // })
        this.previousRecord = this.recentRecords[0]

        this.$refs.shareSetting.init()

        // * 実施日を作成日に固定していた時のデータがある
        if (this.record.trainingDate) {
          this.trainingDate = this.record.trainingDate.toDate() // .toISOString().substr(0, 10)
        } else {
          this.trainingDate = this.record.created.toDate() // .toISOString().substr(0, 10)
        }
        this.imgId = `${this.record.id}-${this.record.body.length}-${Math.floor(Math.random(1) * 10000)}`
        this.recordTimeStr = moment.unix(this.record.updated.seconds).format('YYYY-MM-DD HH:mm')
      }
    }, // init
    addMenu() {
      this.body.push({
        count: 0,
        created: moment(new Date()).format('HH:mm:ss'),
        label: null,
        memo: '',
        sets: []
      })
    },
    onHeaderRight() {
      // this.saveRecordData()
    },
    async saveRecordData(params) {
      if (!this.uid) return false

      var pattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/
      if (!pattern.test(this.clientWeight)) {
        alert('体重は半角数字で入力してください')
        return false
      }

      var recordViewComponent = this.$parent.$parent.$parent
      recordViewComponent.headerRightIcon = {
        label: '保存中',
        icon: 'more_horiz'
      }

      var recordObj = {
        label: this.label,
        memo: this.memo,
        clientWeight: this.clientWeight,
        maxPressure: this.maxPressure,
        minPressure: this.minPressure,
        body: this.body,
        uid: this.uid,
        clientId: params.clientId,
        type: params.recordType,
        // trainingDate: this.$refs.dateSetting.getDate(),
        trainingDate: this.trainingDate,
        // created: this.$refs.dateSetting.getDate(),
        // created: new Date(this.trainingDate),
        updated: new Date()
      }


      if (this.$refs.shareSetting && this.$refs.shareSetting.password) recordObj.password = this.$refs.shareSetting.password

      await this.saveRecord({
        recordObj: recordObj,
        docId: this.docId,
        isNew: this.$route.params.recordId === 'new'
      })

      recordViewComponent.headerRightIcon = {
        label: '保存済み',
        icon: 'done'
      }

      // this.$router.push(`/record/${this.$route.params.clientId}`)
    },
    deleteTrainingMenu(index) {
      var targetRecord = this.records.filter((e) => {
        return e.id === this.docId
      })[0]

      targetRecord.body = targetRecord.body.filter((e, i) => {
        return i !== index
      })

      this.body = targetRecord.body

      this.replaceOneRecord(targetRecord)
    },
    onChangeDate(date) {
      this.trainingDate = date
      // this.autoSave()
    },
    removeImg(src) {
      var letDelete = confirm('削除しますか？')
      if (!letDelete) return false

      this.body = this.record.body.filter((e) => {
        return e.photoURL !== src
      })
      this.record.body = this.body

      this.autoSave()

      // mixpanel.track('onRemoveImg', { createdAt: new Date() })
    },
    afterUpload(url) {
      this.body.push({ photoURL: url })
      this.record.body = this.body
      this.autoSave()

      this.imgId = `${this.record.id}-${this.record.body.length}-${Math.floor(Math.random(1) * 10000)}`
      // mixpanel.track('afterUpload', { createdAt: new Date() })
    },
    upMenu(index) {
      if (index === 0) return false
      var swap = (a, x, y) => {
        a[x] = [a[y], (a[y] = a[x])][0]
        return a
      }
      this.record.body = this.body
      this.record.body = swap(this.record.body, index, index - 1)
      this.body = []
      this.body = this.record.body
      this.autoSave()
    },
    downMenu(index) {
      if (index === this.body.length - 1) return false
      var swap = (a, x, y) => {
        a[x] = [a[y], (a[y] = a[x])][0]
        return a
      }
      this.record.body = this.body
      this.record.body = swap(this.record.body, index, index + 1)
      this.body = []
      this.body = this.record.body
      this.autoSave()
    },
    autoSave() {
      if (!this.uid) return false
      if (this.autoSaveTimer) clearTimeout(this.autoSaveTimer)
      this.autoSaveTimer = setTimeout(this.saveRecordData, 800, { ...this.$route.params })
    },
    async toPreviousRecord() {
      if (this.$route.params.recordId !== 'new') {
        var previousRecord = await this.getPreviousRecordOf({
          created: this.record.created,
          clientId: this.$route.params.clientId,
          type: this.$route.params.recordType
        })
      }
      if (previousRecord) {
        this.$router.push(`/record/${previousRecord.type}/${this.$route.params.clientId}/${previousRecord.id}`)
        this.init()
        // this.$router.go({path: this.$router.currentRoute.path, force: true})
      }
    },
    async toNextRecord() {
      if (this.$route.params.recordId !== 'new') {
        var nextRecord = await this.getNextRecordOf({
          created: this.record.created,
          clientId: this.$route.params.clientId,
          type: this.$route.params.recordType
        })
      }
      if (nextRecord) {
        this.$router.push(`/record/${nextRecord.type}/${this.$route.params.clientId}/${nextRecord.id}`)
        this.init()
        // this.$router.go({path: this.$router.currentRoute.path, force: true})
      }
    },
    updateContent({ index, content }) {
      this.body[index] = content
    },
    updateTrainingList(trainingList) {
      this.trainingList = trainingList
    }
  }
}
</script>
