<template lang="pug">
  div.wrap-item-date-setting.f.fm
    //- div(@click="showDatePicker = true").wrap-time-str.f.fm.pb4
      v-icon(color="#2a2a2a" size="20px").mr6 calendar_today
      span {{dateData}}
    //- div(v-if="showDatePicker").wrap-date-picker
      v-date-picker(
        v-model="dateData"
        locale="jp-ja"
        :day-format="date => new Date(date).getDate()")
    div.mr8
      v-menu(offset-y)
        template(v-slot:activator="{ on }")
          div(v-on="on").wrap-time-str.f.fm.pb4.pr2
            v-icon(size="20px").mr6 calendar_today
            span {{dateData}}
        template
          div.wrap-date-picker
            v-date-picker(
              v-model="dateData"
              locale="jp-ja"
              color="#008080"
              :day-format="date => new Date(date).getDate()")
    div.time-input.f.fm.pb3
      v-icon.mr4 mdi-clock-outline
      select(v-model="hour")
        option(v-for="item in hours") {{item}}
      span :
      select(v-model="minute")
        option(v-for="item in minutes") {{item}}
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-item-date-setting {
  position: relative;
  .wrap-time-str {
    display: inline-flex;
    border-bottom: $basic_border;
    span {
      font-size: 16px;
    }
  }
  .wrap-date-picker {
    position: absolute;
    z-index: 99;
    top: 50px;
  }
  .time-input {
    border-bottom: $basic_border;
    select {
      outline: none;
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import format from 'date-fns/format'

export default {
  props: {
    date: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      // dateData: null,
      // hour: null,
      // minute: null,
      // showDatePicker: false
      // isInitialized: false
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    hours() {
      let hours = []
      for (let i = 0; i <= 23; i++) {
        let hh = String(i).length === 1 ? '0' + String(i) : String(i)
        hours.push(hh)
      }
      return hours
    },
    minutes() {
      let minutes = []
      for (let i = 0; i <= 59; i++) {
        let mm = String(i).length === 1 ? '0' + String(i) : String(i)
        minutes.push(mm)
      }
      return minutes
    },
    dateData: {
      get: function () {
        return format(this.date, 'yyyy-MM-dd')
      },
      set: function (date) {
        this.$emit('onChangeDate', new Date(`${date}T${this.hour}:${this.minute}`))
      }
    },
    hour: {
      get: function () {
        return format(this.date, 'HH')
      },
      set: function (hour) {
        this.$emit('onChangeDate', new Date(`${this.dateData}T${hour}:${this.minute}`))
      }
    },
    minute: {
      get: function () {
        return format(this.date, 'mm')
      },
      set: function (minute) {
        this.$emit('onChangeDate', new Date(`${this.dateData}T${this.hour}:${minute}`))
      }
    }
  },
  watch: {
    // date: function () {
    //   this.dateData = this.date
    // },
    // dateData: function (value) {
    //   this.watchValue()
    // },
    // hour: function (value) {
    //   this.watchValue()
    // },
    // minute: function (value) {
    //   this.watchValue()
    // }
    // dateData: function (newDate) {
    //   if (this.isInitialized) {
    //     this.showDatePicker = false
    //     this.$emit('onChangeDate', new Date(newDate))
    //   } else {
    //     this.isInitialized = true
    //   }
    // }
  },
  created() {
    // this.dateData = this.date.toISOString().substr(0, 10)
    // let timeStr = this.date.toISOString().split('T')[1]
    // this.hour = timeStr.split(':')[0]
    // this.minute = timeStr.split(':')[1]
    // this.dateData = format(this.date, 'yyyy-MM-dd')
    // this.hour = format(this.date, 'HH')
    // this.minute = format(this.date, 'mm')
  },
  methods: {
    // watchValue() {
    //   if (this.isInitialized) {
    //     this.showDatePicker = false
    //     this.$emit('autoSave')
    //     // this.$emit('onChangeDate', new Date(newDate))
    //     this.$emit('onChangeDate', this.getDate())
    //   } else {
    //     this.isInitialized = true
    //   }
    // },
    // getDate() {
    //   return new Date(`${this.dateData}T${this.hour}:${this.minute}`)
    // }
  }
}
</script>
