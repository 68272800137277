<template lang="pug">
  div(v-if="client").wrap-item-client
    div.f.fm
      v-icon.mr8 mdi-account-box-multiple
      // div.wrap-icon.f.fh.mr8
        img(:src="client.photoURL")
      span.name {{client.name}}

</template>

<style lang="scss" scoped>
.wrap-item-client {
  .wrap-icon {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name {
    font-size: 14px;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  props: {
    clientId: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      client: null
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created () {
    this.client = await this.getClientByUid(this.clientId)
  },
  methods: {
    ...mapActionAuth([
      'getClientByUid'
    ])
  }
}
</script>
