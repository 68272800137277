<template lang="pug">
  div.wrap-module-consultation.pb60
    ItemRecordSubHeader(
      v-if="consultation && records && $route.name !== 'client_record'"
      :record="consultation"
      :label="label"
      type="consultation"
      @init="init")
    div(v-if="$route.name !== 'client_record'").pt70
    div.wrap-record-meta.f.flex-between.mb40.pb14
      div.f.fm.flex-wrap.mb12
        ItemRecordClient(v-if="consultation" :clientId="consultation.clientId").mr12.s-mb8
        span.s-hide.mr8 /
        ItemRecordTrainer(v-if="consultation" :trainerId="consultation.uid")
      div.wrap-updated-at-in-set.f
        v-icon(color="#999").check-icon.mr4 check
        span(v-if="recordTimeStr").record-time.pt2 {{recordTimeStr}}
    div.wrap-consultation-info.pb30.mb30
      div.wrap-label
        v-text-field(
          v-if="!previousConsultation"
          v-model="label"
          label="Title"
          color="#2a2a2a"
          @change="autoSave").mb12
        v-text-field(
          v-else
          v-model="label"
          label="Title"
          color="#2a2a2a"
          :hint="`Previous Log: ${previousConsultation.label}`"
          @change="autoSave").mb12
      div.wrap-goal.mb12
        h3.mb2 Goal:
        div(v-if="!previousConsultation").f
          v-text-field(
            v-model="goalThisMonth"
            label="This Month Goal"
            color="#2a2a2a"
            @change="autoSave").mr12
          v-text-field(
            v-model="goalLastMonth"
            label="Last Month Goal"
            color="#2a2a2a"
            @change="autoSave")
        div(v-else).f
          v-text-field(
            v-model="goalThisMonth"
            label="This Month Goal"
            color="#2a2a2a"
            :hint="`Previous Log: ${previousConsultation.goalThisMonth}`"
            @change="autoSave").mr12
          v-text-field(
            v-model="goalLastMonth"
            label="Last Month Goal"
            color="#2a2a2a"
            :hint="`Previous Log: ${previousConsultation.goalLastMonth}`"
            @change="autoSave")
      div.wrap-meals.mb12
        h3.mb2 Meal:
        div(v-if="!previousConsultation").f
          v-text-field(
            v-model="mealMorning"
            label="Morning"
            color="#2a2a2a"
            @change="autoSave").mr12
          v-text-field(
            v-model="mealLunch"
            label="Lunch"
            color="#2a2a2a"
            @change="autoSave")
        div(v-else).f
          v-text-field(
            v-model="mealMorning"
            label="Morning"
            color="#2a2a2a"
            :hint="previousConsultation.mealMorning"
            @change="autoSave").mr12
          v-text-field(
            v-model="mealLunch"
            label="Lunch"
            color="#2a2a2a"
            :hint="previousConsultation.mealLunch"
            @change="autoSave")

        div(v-if="!previousConsultation").f
          v-text-field(
            v-model="mealDinner"
            label="Dinner"
            color="#2a2a2a"
            @change="autoSave").mr12
          v-text-field(
            v-model="mealOther"
            label="Other"
            color="#2a2a2a"
            @change="autoSave")
        div(v-else).f
          v-text-field(
            v-model="mealDinner"
            label="Dinner"
            color="#2a2a2a"
            :hint="previousConsultation.mealDinner"
            @change="autoSave").mr12
          v-text-field(
            v-model="mealOther"
            label="Other"
            color="#2a2a2a"
            :hint="previousConsultation.mealOther"
            @change="autoSave")

      div.wrap-condition.mb12
        h3.mb2 Condition:
        div.wrap-sleep
          v-textarea(
            v-if="!previousConsultation"
            v-model="sleep"
            rows="3"
            label="Sleep"
            @change="autoSave"
            color="#2a2a2a")
          v-textarea(
            v-else
            v-model="sleep"
            rows="3"
            label="Sleep"
            color="#2a2a2a"
            :hint="previousConsultation.sleep"
            @change="autoSave")

        div.defecation
          v-textarea(
            v-if="!previousConsultation"
            v-model="defecation"
            rows="3"
            label="Defecation"
            color="#2a2a2a"
            @change="autoSave")
          v-textarea(
            v-else
            v-model="defecation"
            rows="3"
            label="Defecation"
            color="#2a2a2a"
            :hint="previousConsultation.defecation"
            @change="autoSave")

        div.wrap-workout
          v-textarea(
            v-if="!previousConsultation"
            v-model="workout"
            rows="3"
            label="Workout"
            color="#2a2a2a"
            @change="autoSave")
          v-textarea(
            v-else
            v-model="workout"
            rows="3"
            label="Workout"
            color="#2a2a2a"
            :hint="previousConsultation.workout"
            @change="autoSave")

        div.wrap-other
          v-textarea(
            v-if="!previousConsultation"
            v-model="other"
            rows="3"
            label="Other"
            color="#2a2a2a"
            @change="autoSave")
          v-textarea(
            v-else
            v-model="other"
            rows="3"
            label="Other"
            color="#2a2a2a"
            :hint="previousConsultation.other"
            @change="autoSave")

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-consultation {
  width: 93%;
  max-width: 820px;
  margin: 0 auto;
  padding-top: 24px;
  .wrap-record-meta {
    border-bottom: $basic_border;
  }
}
</style>

<style lang="scss">
.wrap-updated-at-in-set {
  i {
    height: 24px;
  }
}
</style>

<script>
import moment from 'moment'
import ItemRecordSubHeader from '@/components/item/ItemRecordSubHeader.vue'
import ItemRecordTrainer from '@/components/item/ItemRecordTrainer.vue'
import ItemRecordClient from '@/components/item/ItemRecordClient.vue'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const { mapState: mapStateRecord, mapActions: mapActionsRecord } = createNamespacedHelpers('record')

export default {
  components: {
    ItemRecordSubHeader,
    ItemRecordTrainer,
    ItemRecordClient
  },
  props: {
  },
  data () {
    return {
      consultation: null,
      previousConsultation: null,
      label: '',
      goalThisMonth: '',
      goalLastMonth: '',
      mealMorning: '',
      mealLunch: '',
      mealDinner: '',
      mealOther: '',
      sleep: '',
      defecation: '',
      workout: '',
      other: '',
      recordTimeStr: '',
      autoSaveTimer: null
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...mapStateRecord(['records'])
  },
  async created () {
    await this.init()
  },
  methods: {
    ...mapActionsRecord([
      'getRecordById',
      'getPreviousRecordOf',
      'saveRecord'
    ]),
    async init () {
      this.consultation = await this.getRecordById(this.$route.params.recordId)

      this.previousConsultation = await this.getPreviousRecordOf(this.consultation)

      this.label = this.consultation.label
      this.goalThisMonth = this.consultation.goalThisMonth
      this.goalLastMonth = this.consultation.goalLastMonth
      this.mealMorning = this.consultation.mealMorning
      this.mealLunch = this.consultation.mealLunch
      this.mealDinner = this.consultation.mealDinner
      this.mealOther = this.consultation.mealOther
      this.sleep = this.consultation.sleep
      this.defecation = this.consultation.defecation
      this.workout = this.consultation.workout
      this.other = this.consultation.other

      this.recordTimeStr = moment.unix(this.consultation.updated.seconds).format('YYYY-MM-DD HH:mm')
    },
    autoSave () {
      if (this.autoSaveTimer) clearTimeout(this.autoSaveTimer)
      this.autoSaveTimer = setTimeout(this.saveConsultationData, 800)
    },
    async saveConsultationData () {
      var recordViewComponent = this.$parent.$parent.$parent
      recordViewComponent.headerRightIcon = {
        label: '保存中',
        icon: 'more_horiz'
      }

      var consultationObj = {
        label: this.label,
        goalThisMonth: this.goalThisMonth,
        goalLastMonth: this.goalLastMonth,
        mealMorning: this.mealMorning,
        mealLunch: this.mealLunch,
        mealDinner: this.mealDinner,
        mealOther: this.mealOther,
        sleep: this.sleep,
        defecation: this.defecation,
        workout: this.workout,
        other: this.other,
        uid: this.uid,
        clientId: this.$route.params.clientId,
        type: 'consultation',
        created: this.consultation.created,
        updated: new Date()
      }

      await this.saveRecord({
        recordObj: consultationObj,
        docId: this.$route.params.recordId,
        isNew: (this.$route.params.recordId === 'new')
      })

      recordViewComponent.headerRightIcon = {
        label: '保存済み',
        icon: 'done'
      }
    }
  }
}
</script>
