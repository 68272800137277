<template lang="pug">
  div.wrap-atom-menu-unit-editor
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        span(v-on="on").unit-count-label {{`${newDefaultUnit} / ${newDefaultCountUnit}`}}
      v-list.px12.pb10.mt4
        div.wrap-input-unit.f.fm.mr8.mb4
          span.mr4 重量：
          div(v-for="(unit, index) in ['kg', 'lbs', '自重']").f.fm.mr8
            label.f.fm
              div(:class="{'is-active': newDefaultUnit === unit}").check-icon.mr4
              input(v-model="newDefaultUnit" type="radio" :value="unit").radio-input.mr4
              span {{unit}}
        div.wrap-input-count-unit.f.fm
          span.mr4 単位：
          div(v-for="(unit, index) in ['回', '分', '秒']").f.fm.mr8
            label.f.fm
              div(:class="{'is-active': newDefaultCountUnit === unit}").check-icon.mr4
              input(v-model="newDefaultCountUnit" type="radio" :value="unit").radio-input.mr4
              span {{unit}}

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-atom-menu-unit-editor {
  .unit-count-label {
    color: $active_color;
    // border-bottom: solid $active_color 1px;
    // color: #2a2a2a;
    // border-bottom: solid #2a2a2a 1px;
  }
}

.radio-input {
  display: none;
}
.check-icon {
  display: block;
  width: 14px;
  height: 14px;
  border: solid #999 2px;
  border-radius: 50%;
  &.is-active {
    border: solid $active_color 2px;
    background: $active_color;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions: mapActionsRecord } = createNamespacedHelpers('record')

export default {
  props: {
    defaultUnit: {
      type: String,
      required: true
    },
    defaultCountUnit: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      newDefaultUnit: null,
      newDefaultCountUnit: null
    }
  },
  watch: {
    newDefaultUnit: function (newUnit) {
      this.$emit('setDefaultUnit', newUnit)
      // this.defaultUnit = newUnit
    },
    newDefaultCountUnit: function (newCountUnit) {
      this.$emit('setDefaultCount', newCountUnit)
      // this.defaultCountUnit = newCountUnit
    }
  },
  async created () {
    this.updateUnits({
      defaultUnit: this.defaultUnit,
      defaultCountUnit: this.defaultCountUnit
    })
    // this.init()
  },
  methods: {
    ...mapActionsRecord(['getSet', 'updateSet', 'deleteSet']),
    // init () {
    //   this.newDefaultUnit = this.defaultUnit
    //   this.newDefaultCountUnit = this.defaultCountUnit
    // },
    updateUnits (units) {
      this.newDefaultUnit = units.defaultUnit
      this.newDefaultCountUnit = units.defaultCountUnit

      // mixpanel.track('updateUnits', { createdAt: new Date() })
    }
  }
}
</script>
