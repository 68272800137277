<template lang="pug">
  div.wrap-module-inbody.pb60
    ItemRecordSubHeader(
      v-if="inbody && records && $route.name !== 'client_record'"
      :record="inbody"
      :label="label"
      type="inbody"
      @init="init")
    div(v-if="$route.name !== 'client_record'").pt70
    div.wrap-record-meta.f.flex-between.mb40.pb14
      div.f.fm.flex-wrap.mb12
        ItemRecordClient(v-if="inbody" :clientId="inbody.clientId").mr12.s-mb8
        span.s-hide.mr8 /
        ItemRecordTrainer(v-if="inbody" :trainerId="inbody.uid")
      div.wrap-updated-at-in-set.f
        v-icon.mr4.pb2 check
        span(v-if="recordTimeStr").record-time.pt2 {{recordTimeStr}}
    div(v-if="inbody").wrap-inbody-info.mb30
      div.mt30.mb2
        v-text-field(
          v-model="label"
          label="Title"
          @change="autoSave")
      div.wrap-measured.mb20
        h3 計測日
        ItemDateSetting(
          :date="measuredDate"
          @onChangeDate="onChangeDate")
        //- div(@click="showDatePicker = true").wrap-time-str.f.fm.pb4
        //-   v-icon(color="#2a2a2a" size="20px").mr6 calendar_today
        //-   span {{measuredDate}}
        //- div(v-if="showDatePicker").wrap-date-picker
        //-   v-date-picker(v-model="measuredDate" locale="jp-ja" :day-format="date => new Date(date).getDate()")
      div.wrap-imgs.mb12
        div(v-for="src in inbody.imgs").img.mb8
          img(:src="src")
          div.wrap-remove
            v-icon(@click="removeImg(src)" color="#2a2a2a" size="28px") cancel
      div.wrap-uploader
        ItemInbodyImgUploader(
          :imgId="imgId"
          ref="mediaUploader"
          @afterUpload="afterUpload")

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-inbody {
  width: 93%;
  max-width: 820px;
  margin: 0 auto;
  padding-top: 24px;
  .wrap-record-meta {
    border-bottom: $basic_border;
  }
  .wrap-measured {
    position: relative;
    h3 {
      color: #999;
      font-size: 10px;
    }
    .wrap-time-str {
      display: inline-flex;
      border-bottom: solid #999 1px;
      span {
        font-size: 16px;
      }
    }
    .wrap-date-picker {
      position: absolute;
      z-index: 99;
      top: 50px;
    }
  }
  .wrap-imgs {
    .img {
      position: relative;
      width: 100%;
      img {
        width: 100%;
        object-fit: cover;
      }
      .wrap-remove {
        position: absolute;
        top: 12px;
        right: 12px;
        opacity: 0.8;
      }
    }
  }
}
</style>

<style lang="scss">
.wrap-updated-at-in-set {
  i {
    height: 24px;
  }
}
</style>

<script>
import moment from 'moment'
import ItemRecordTrainer from '@/components/item/ItemRecordTrainer'
import ItemRecordClient from '@/components/item/ItemRecordClient'
import ItemRecordSubHeader from '@/components/item/ItemRecordSubHeader'
import ItemDateSetting from '@/components/item/ItemDateSetting'
import ItemInbodyImgUploader from '@/components/item/ItemInbodyImgUploader'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const { mapState: mapStateRecord, mapActions: mapActionsRecord } = createNamespacedHelpers('record')

export default {
  components: {
    ItemRecordTrainer,
    ItemRecordClient,
    ItemRecordSubHeader,
    ItemDateSetting,
    ItemInbodyImgUploader
  },
  props: {
  },
  data () {
    return {
      inbody: null,
      label: '',
      recordTimeStr: '',
      autoSaveTimer: null,
      imgId: '',
      measuredDate: null,
      showDatePicker: false
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...mapStateRecord(['records'])
  },
  watch: {
    // measuredDate: function (newDate) {
    //   this.showDatePicker = false
    //   this.inbody.measuredAt = new Date(newDate)
    //   this.autoSave()

    //   mixpanel.track("setMeasuredDate", { createdAt: new Date() })
    // }
  },
  async created () {
    await this.init()
  },
  methods: {
    ...mapActionsRecord([
      'getRecordById',
      'saveRecord'
    ]),
    async init () {
      this.inbody = await this.getRecordById(this.$route.params.recordId)

      this.label = this.inbody.label

      this.measuredDate = new Date(this.inbody.measuredAt.toDate()).toISOString().substr(0, 10)

      this.recordTimeStr = moment.unix(this.inbody.updated.seconds).format('YYYY-MM-DD HH:mm')
      this.imgId = `${this.inbody.id}-${this.inbody.imgs.length}-${Math.floor(Math.random(1) * 10000)}`
    },
    onChangeDate (date) {
      this.measuredDate = date
      this.inbody.measuredAt = new Date(date)
      this.autoSave()
    },
    afterUpload (url) {
      this.inbody.imgs.push(url)
      this.autoSave()

      this.imgId = `${this.inbody.id}-${this.inbody.imgs.length}-${Math.floor(Math.random(1) * 10000)}`
      // mixpanel.track('afterUpload', { createdAt: new Date() })
    },
    removeImg (src) {
      var letDelete = confirm('削除しますか？')
      if (!letDelete) return false

      this.inbody.imgs = this.inbody.imgs.filter((img) => {
        return img !== src
      })
      this.autoSave()

      // mixpanel.track('onRemoveImg', { createdAt: new Date() })
    },
    autoSave () {
      if (this.autoSaveTimer) clearTimeout(this.autoSaveTimer)
      this.autoSaveTimer = setTimeout(this.saveInbodyData, 800)
    },
    async saveInbodyData () {
      var recordViewComponent = this.$parent.$parent.$parent
      recordViewComponent.headerRightIcon = {
        label: '保存中',
        icon: 'more_horiz'
      }

      var inbodyObj = {
        label: this.label,
        uid: this.uid,
        imgs: this.inbody.imgs,
        measuredAt: this.inbody.measuredAt,
        clientId: this.$route.params.clientId,
        type: 'inbody',
        created: this.inbody.updated,
        updated: new Date()
      }

      await this.saveRecord({
        recordObj: inbodyObj,
        docId: this.$route.params.recordId,
        isNew: (this.$route.params.recordId === 'new')
      })

      recordViewComponent.headerRightIcon = {
        label: '保存済み',
        icon: 'done'
      }
    }
  }
}
</script>
