<template lang="pug">
  div.wrap-item-menu.pl12
    div.wrap-training.f.fm.mb4
      v-autocomplete(
        v-if="!selectedTraining"
        v-model="selectedTraining"
        :items="trainingList"
        item-text="label"
        item-id="sampleLabel"
        label="トレーニングを選択"
        color="#2a2a2a"
        hide-no-data
        return-object).menu-selector.mr12

      span(v-if="selectedTraining").menu-selector.fixed-selector-input.pt10.pb7.mr12 {{selectedTraining.label}}
      v-icon(@click="onDeleteMenu" color="#999" size="20px") delete

    div.wrap-time.f.fm.mb8
      v-icon(size="16px" color="#999").mr4 access_time
      span {{`${content.created}`}}

    div(v-if="selectedTraining").wrap-memo.mb8
      div(v-if="!showMemo" @click="onAddMemo").write-memo.f.fm
        v-icon(color="#008080" size="18px") edit
        span メモを追加
      v-textarea(
        ref="memoInput"
        v-if="showMemo"
        v-model="memo"
        rows="2"
        label="トレーニングメモ")

    div(v-if="!selectedTraining").wrap-add-new-training-menu
      div(v-if="!showAddNewTrainingMenuWindow" @click="onAddNewTrainingMenu").add-new-training-menu.f.fm.mb12.cursor-pointer
        v-icon(color="#008080" size="20px") add
        span 新規でトレーニング項目を追加
      div(v-if="showAddNewTrainingMenuWindow").edit-new-training-window.px20.pt20.pb14.mb12
        div.f.mb10
          input(ref="newTrainingInput" v-model="newTrainingLabel" placeholder="トレーニングメニューの項目名を入力").text-input.py3.mr8
          span(@click="addNewTrainingMenu").add-button.px8.pt6 追加
        div.f.flex-wrap.mb12
          div.wrap-input-unit.f.fm.mr8
            span.mr4 重量：
            div(v-for="(unit, index) in ['kg', 'lbs', '自重']").f.fm.mr8
              label.f.fm
                div(:class="{'is-active': newDefaultUnit === unit}").check-icon.mr4
                input(v-model="newDefaultUnit" type="radio" :value="unit").radio-input.mr4
                span {{unit}}
          div.wrap-input-count-unit.f.fm
            span.mr4 単位：
            div(v-for="(unit, index) in ['回', '分', '秒']").f.fm.mr8
              label.f.fm
                div(:class="{'is-active': newDefaultCountUnit === unit}").check-icon.mr4
                input(v-model="newDefaultCountUnit" type="radio" :value="unit").radio-input.mr4
                span {{unit}}

    div.wrap-sets.pb12
      div(v-if="selectedTraining").f.fm.flex-wrap.ml2
        AtomEditableSet(
          ref="atomSets"
          v-for="(setId, index) in content.sets"
          :setId="setId"
          :recordBodyContent="content"
          :selectedTraining="selectedTraining"
          :defaultUnit="defaultUnit"
          :defaultCountUnit="defaultCountUnit"
          @setDefaultUnit="(unit) => { defaultUnit = unit }"
          @setDefaultCount="(count) => { defaultCountUnit = count }"
          @initMenuUnitEditor="initMenuUnitEditor"
          @autoSave="$emit('autoSave')")
        div.wrap-unit-editor.f.fm.pb14
          v-icon(@click="addSet" size="28px" color="#008080").mr10 add_circle
          AtomMenuUnitEditor(
            ref="menuUnitEditor"
            v-if="defaultUnit && defaultCountUnit"
            :defaultUnit="defaultUnit"
            :defaultCountUnit="defaultCountUnit"
            @setDefaultUnit="(unit) => { defaultUnit = unit }"
            @setDefaultCount="(count) => { defaultCountUnit = count }")

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-item-menu {
  border-left: solid $active_color;
  .wrap-training {
    .menu-selector {
      font-weight: bold;
      width: calc(100% - 30px);
      // width: 80%;
    }
    .fixed-selector-input {
      border-bottom: solid 1px #999;
    }
  }
  .wrap-time {
    span {
      font-size: 12px;
      color: #999;
    }
  }
  .wrap-memo {
    .write-memo {
      span {
        font-size: 12px;
        color: $active_color;
      }
    }
  }
  .wrap-add-new-training-menu {
    position: relative;
    .add-new-training-menu {
      span {
        color: $active_color;
      }
    }
    .edit-new-training-window {
      display: inline-block;
      border-radius: 3px;
      box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.1);
      .text-input {
        outline: none;
        width: 288px;
        border-bottom: solid 1px #999;
        font-size: 16px;
      }
      .radio-input {
        display: none;
      }
      .check-icon {
        display: block;
        width: 14px;
        height: 14px;
        border: solid #999 2px;
        border-radius: 50%;
        &.is-active {
          border: solid $active_color 2px;
          background: $active_color;
        }
      }
      .add-button {
        background: #2a2a2a;
        border-radius: 3px;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
  .wrap-sets {
    .wrap-set-input {
      height: 24px;
      .wrap-input {
        position: relative;
        border-bottom: solid 1px;
        input {
          // width: 20px;
          text-align: center;
          outline: none;
        }
        select {
          // width: 20px;
          text-align: center;
          outline: none;
          position: absolute;
          left: 0;
          right: 0;
        }
      }
    }
    // .add-set {
    //   cursor: pointer;
    //   color: #1976d2;
    //   font-weight: bold;
    // }
  }
}
</style>

<style lang="scss">
.wrap-item-menu {
  .v-text-field__details {
    display: none;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import AtomSet from '@/components/atom/AtomSet.vue'
import AtomMenuUnitEditor from '@/components/atom/AtomMenuUnitEditor.vue'
import AtomEditableSet from '@/components/atom/AtomEditableSet.vue'
const { mapActions: mapActionsRecord } = createNamespacedHelpers('record')
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    AtomSet,
    AtomEditableSet,
    AtomMenuUnitEditor
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    trainingList: {
      type: Array,
      required: true
    },
    recentRecords: {
      type: Array,
      required: true
    },
    previousRecord: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      label: null,
      setId: null,
      selectedTraining: null,
      isInitialized: false,
      defaultUnit: 'kg',
      defaultCountUnit: '回',
      valueWeight: 0,
      valueCount: 0,
      showAddNewTrainingMenuWindow: false,
      memo: '',
      showMemo: false,
      newTrainingLabel: '',
      newDefaultUnit: 'kg',
      newDefaultCountUnit: '回'
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    unitNum: () => {
      var list = []
      for (var i = 0; i < 300; i = Number((i + 0.25).toFixed(2))) {
        // list.push(String(i))
        list.push(i)
      }
      return list
    },
    unitCountNum: () => {
      var list = []
      for (var i = 0; i < 300; i++) {
        list.push(i)
      }
      return list
    }
  },
  watch: {
    selectedTraining: async function (newTrainingObj) {
      // [bug] オートセーブ中にメニューを追加するとundefinedが飛んでくる
      if (!newTrainingObj) return false

      if (newTrainingObj.label === '+ 新規トレーニングメニューの追加') {
        this.selectedTraining = null
        this.onAddNewTrainingMenu()
        return false
      }

      var self = this
      self.content.label = newTrainingObj.label

      if (this.content.sets.length === 0) {
        if (!this.content.sets || !this.content.sets[0]) {
          var menuItem = this.trainingList
            .filter(e => { return e.label === this.content.label })[0]

          this.defaultUnit = menuItem.defaultUnit
          this.defaultCountUnit = menuItem.defaultCountUnit
          this.addSet()
        }
      }

      if (this.isInitialized) this.$emit('autoSave')
      this.isInitialized = true
    },
    memo: function (newMemo) {
      var self = this
      self.content.memo = newMemo
      this.$emit('autoSave')
    },
    valueWeight: function () {
      // this.valueWeight = this.valueWeight.replace(/\D/g, '')
    },
    valueCount: function () {
      // this.valueCount = this.valueCount.replace(/\D/g, '')
    },
    content: function () {
      if (!this.content.label) { return }

      this.selectedTraining = this.trainingList.filter(e => {
        return (this.content.label === e.label)
      })[0]
      this.memo = this.content.memo
      // this.defaultUnit = this.selectedTraining.defaultUnit
      // this.defaultCountUnit = this.selectedTraining.defaultCountUnit
    }
  },
  async created () {
    if (this.content.memo !== '') {
      this.memo = this.content.memo
      this.showMemo = true
    }

    if (this.content.label) {
      this.selectedTraining = this.trainingList.filter(e => {
        return (this.content.label === e.label)
      })[0]

      // 後でメニューなどが編集されてこのトレーニングがなかった場合
      if (!this.selectedTraining) {
        this.selectedTraining = {
          label: this.content.label,
          simpleLabel: this.content.label,
          defaultUnit: 'kg',
          defaultCountUnit: '回'
        }
      }
    }
  },
  methods: {
    ...mapActionsRecord(['getSets', 'createNewSet', 'deleteSets', 'pushNewMenuItem']),
    // async addSet () {
    //   var pattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/
    //   if (!pattern.test(this.valueWeight) || !pattern.test(this.valueCount)) {
    //     alert("半角数字で入力してください")
    //     return false
    //   }

    //   var menuItem = this.trainingList // this.menuItems.body
    //     .filter(e => { return e.label === this.content.label })[0]

    //   var title = this.content.sets.length + 1
    //   var defaultUnit = menuItem.defaultUnit
    //   var defaultCountUnit = menuItem.defaultCountUnit

    //   var setObj = {
    //     title: title,
    //     memo: this.memo,
    //     unit: defaultUnit,
    //     countUnit: defaultCountUnit,
    //     weight: this.valueWeight,
    //     count: this.valueCount
    //   }

    //   this.setId = await this.createNewSet(setObj)
    //   this.content.sets.push(this.setId)

    //   this.$emit("autoSave")
    // },
    async addSet () {
      // var menuItem = this.trainingList // this.menuItems.body
      //   .filter(e => { return e.label === this.content.label })[0]

      var title = this.content.sets.length + 1
      var defaultUnit = this.defaultUnit
      var defaultCountUnit = this.defaultCountUnit

      if (this.content.sets.length === 0) {
        var weight = 0
        var count = 0

        // 前回の記録に同じトレーニングがあったらそのトレーニングと同じセットを入れる
        // if (this.previousRecord) {
        //   var sameTraining = this.previousRecord.body.filter(e => {
        //     return this.content.label === e.label
        //   })[0]
        //   if (sameTraining) {
        //     var previousSameSets = await this.getSets(sameTraining.sets)
        //   }
        // }
        // 記録が複数あって、同じトレーニングがあったらそのトレーニングと同じセットを入れる
        if (this.recentRecords) {
          for (var i=0; i < this.recentRecords.length; i++) {
            var sameTraining = this.recentRecords[i].body.filter(e => {
              return this.content.label === e.label
            })[0]
            if (sameTraining) break
          }
          if (sameTraining) {
            var previousSameSets = await this.getSets(sameTraining.sets)
          }
        }
      } else {
        // 最新のセットと同じ値をデフォルトで入れる
        var latestSet = this.$refs.atomSets[this.content.sets.length - 1].set
        weight = latestSet.weight
        count = latestSet.count
      }

      var self = this
      if (this.content.sets.length === 0 && previousSameSets && previousSameSets[0]) {
        var promises = previousSameSets.map(set => {
          return this.createNewSet(set)
        })
        var newSetIds = await Promise.all(promises)
        self.content.sets = newSetIds
      } else {
        var setObj = {
          title: title,
          memo: this.memo,
          unit: defaultUnit,
          countUnit: defaultCountUnit,
          weight: weight,
          count: count
        }

        this.setId = await this.createNewSet(setObj)
        self.content.sets.push(this.setId)
      }

      this.$emit('autoSave')

      // mixpanel.track('addSet', { createdAt: new Date() })
    },
    onDeleteMenu () {
      this.$emit('deleteTrainingMenu', this.index)

      // mixpanel.track('onDeleteMenu', { createdAt: new Date() })
    },
    onAddNewTrainingMenu () {
      this.showAddNewTrainingMenuWindow = true

      this.$nextTick(() => {
        this.$refs.newTrainingInput.focus()
      })
      // this.$refs.newTrainingInput.focus()

      // mixpanel.track('onAddNewTrainingMenu', { createdAt: new Date() })
    },
    addNewTrainingMenu () {
      var self = this
      self.content.label = this.newTrainingLabel
      var newTrainingMenuObj = {
        defaultUnit: this.newDefaultUnit,
        defaultCountUnit: this.newDefaultCountUnit,
        label: this.newTrainingLabel,
        simpleLabel: this.newTrainingLabel
      }

      this.pushNewMenuItem({
        // docId: this.uid,
        trainingMenuObj: newTrainingMenuObj
      })

      self.trainingList.unshift(newTrainingMenuObj)
      this.selectedTraining = newTrainingMenuObj
      this.defaultUnit = this.newDefaultUnit
      this.defaultCountUnit = this.newDefaultCountUnit
      this.showAddNewTrainingMenuWindow = false
    },
    onAddMemo () {
      this.showMemo = true
      this.$nextTick(() => {
        this.$refs.memoInput.$refs.input.focus()
      })

      // mixpanel.track('onAddMemo', { createdAt: new Date() })
    },
    setDefaultWeight (weight) {
      this.valueWeight = weight
    },
    setDefaultCount (count) {
      this.valueCount = count
    },
    initMenuUnitEditor (units) {
      this.$refs.menuUnitEditor.updateUnits(units)
    }
  }
}
</script>
