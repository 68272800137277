<template lang="pug">
  div.wrap-item-share-setting
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        v-icon(v-on="on" size="20px").mr6 share
        // div(v-on="on").share-button.f.fm.px10.py8
          v-icon(color="#fff" size="16px").mr6 share
          span.pb1 共有設定
      v-list.py12.px8.mt6
        div(@click.stop="() => {}").wrap-window
          div(v-if="hasPassword").wrap-share-setting-with-password.f.fh
            div.pb12
              div.f.fm
                v-text-field(
                  label="Password"
                  v-model="password"
                  :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="showPw ? 'text' : 'password'"
                  @click:append="showPw = !showPw").mr8
                span(@click="savePassword" :class="{'is-active': saveIsActive}").button-save.fz14 保存
              div(v-clipboard:copy="copyLink" v-clipboard:success="onCopy").link.f.fm
                v-icon(color="#008080").mr4 link
                span.line-clamp-1.fz14 URLとパスワードをコピー
          div(v-else).wrap-share-setting-no-password.f.fh
            span(@click="createPassword").button-create-share-url.px10.py8.cursor-pointer 共有リンクを作成

</template>

<style lang="scss">
@import "@/scss/_variables.scss";

.wrap-item-share-setting {
  .share-button {
    background: $primary_bg_color;
    border-radius: 3px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
  }
}

.wrap-share-setting-no-password {
  width: 240px;
  height: 120px;
  .button-create-share-url {
    background: $active_color;
    border-radius: 3px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
  }
}
.wrap-share-setting-with-password {
  width: 240px;
  height: 120px;
  .button-save {
    color: #999;
    &.is-active {
      color: $active_color;
    }
  }
  .link {
    cursor: pointer;
    span {
      width: 200px;
      color: $active_color;
    }
  }
}
</style>

<script>
import db from '@/components/utils/firebase'

import { createNamespacedHelpers } from 'vuex'

export default {
  components: {
  },
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      password: null,
      saveIsActive: true,
      hasPassword: false,
      showPw: true,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 4 || '4文字以上'
      }
    }
  },
  computed: {
    previewLink () {
      return `${location.origin}/preview/${this.$route.params.recordType}/${this.$route.params.clientId}/${this.$route.params.recordId}`
    },
    copyLink () {
      return `URL: ${this.previewLink}\nパスワード： ${this.password}`
    }
  },
  watch: {
    password: function (newVal) {
      this.saveIsActive = (newVal !== '')
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.hasPassword = false
      this.showPw = true
      if (this.record.password) {
        this.password = this.record.password
        this.hasPassword = true
      }
    },
    createPassword () {
      var length = 5
      var charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      var retVal = ''
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n))
      }
      var self = this
      self.record.password = retVal
      this.password = retVal
      this.hasPassword = true

      this.savePassword()

      // mixpanel.track("onCreatePassword", { createdAt: new Date() })
    },
    savePassword () {
      if (this.password.length < 4) {
        alert('4文字以上で設定してください。')
        return false
      }
      this.saveIsActive = false
      this.$emit('autoSave')
      setTimeout(() => {
        this.saveIsActive = true
      }, 400)
    },
    onCopy () {
      alert(`URLとパスワードをコピーされました。`)
      // mixpanel.track("onCopy", { createdAt: new Date() })
    }
  }
}
</script>
